import {Component, Provide, Vue, Watch,} from 'vue-property-decorator';
import common from '../../Common/Index.vue'
import {OrderIdReq} from "@/interface/res/mine";
import {GetLogisticsApi} from "@/network/modules/mine";


@Component({
  components: {
    common
  },
  computed: {
  }
})
export default class Logistics extends Vue {
  @Provide() id:Array<number> = []
  @Provide() loading:boolean = false
  @Provide() data:{
    com:string,
    condition:string,
    ischeck:number,
    state:number,
    stateStr:string,
    logisticsName:string,
    logo:string,
    nu:string,
    orderNo:string,
  } = {
    com:"",
    condition:"",
    ischeck:-1,
    state:-1,
    stateStr:'',
    logisticsName:"",
    logo:"",
    nu:"",
    orderNo:"",
  }
  @Provide() list:Array<{context?:string,ftime?:string,time?:string}> = []
  @Provide() cusOrderDetail:Array<any> = []

  created(){
    const _this:any = this;
    window.scrollTo({top:0})
    if(!_this.$base.isNull(_this.$route.query.i)){
      _this.id = _this.$decryptBy(_this.$route.query.i)
      _this.GetLogisticsFn()
    }

  }

  searchKeyword() { //关键字高亮
    const _this:any = this;
    _this.list.map((item:any) => {
      let keyword = item.context.match(/\d+(.\d+)?/g); //正则匹配
      if (keyword === null) {
        return
      }
      for (const key of keyword) {
        if (key.length > 3) {
          item.context = item.context.replace(key, `<b style="color: #FFCA85">${key}</b>`)
        }
      }
    })
  }


  /**
   * @Author HS
   * @Date 2021/8/12 10:28 上午
   * @Description: 查询物流
   * @Params: null
   * @Return: null
   */
  async GetLogisticsFn(){
    const _this:any = this;
    let params:OrderIdReq = {
      orderId:_this.id
    }
    const res = await GetLogisticsApi(params)
    const { code, msg, data } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }

    let stateStr = ''
    switch (data.state) {
      case 0:
        stateStr = "在途"
        break
      case 1:
        stateStr = "揽收"
        break
      case 2:
        stateStr = "疑难"
        break
      case 3:
        stateStr = "签收"
        break
      case 4:
        stateStr = "退签"
        break
      case 5:
        stateStr = "派件"
        break
      case 6:
        stateStr = "退回"
        break
      case 7:
        stateStr = "转单"
        break
      case 10:
        stateStr = "待清关"
        break
      case 11:
        stateStr = "清关中"
        break
      case 12:
        stateStr = "已清关"
        break
      case 13:
        stateStr = "清关异常"
        break
      case 14:
        stateStr = "拒签"
        break
    }

    _this.data = {
      com:data.com,
      condition:data.condition,
      ischeck:data.ischeck,
      state:data.state,
      stateStr:stateStr,
      logisticsName:data.logisticsName,
      logo:data.logo,
      nu:data.nu,
      orderNo:data.orderNo,
    }
    _this.list = data.data
    _this.cusOrderDetail = data.cusOrderDetail
    _this.searchKeyword()
  }


  /**
   * @Author HS
   * @Date 2021/7/8 11:46 上午
   * @Description: 跳转到详情
   * @Params: { string ： id - true } [id]
   * @Params: { string ： iType - true } [定单类型: 0-丝秘花园 1-储绣家居 2-高级定制]
   * @Return: null
   */
  goDetails(id:string,iType:string){
    const _this:any = this;
    if(iType == '2')return;
    let link = ''
    if(iType == '0'){
      link = _this.$base.routeLinkSplice(_this.$IndexConfig.embroideryHomeDetail.path,`type=${iType}&i=`+id)
    }
    if(iType == '1'){
      link = _this.$base.routeLinkSplice(_this.$IndexConfig.embroideryHomeDetail.path,`type=${iType}&i=`+id)
    }
    _this.$base.goRouter(link)
  }

}
